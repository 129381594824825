
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/store/index'
import Spinner from '@/components/SpinnerComponent.vue'

export default defineComponent({
  components: {
    Spinner
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const menu = ref()
    const items = ref([
      {
        label: 'Logout',
        command: () => {
          logout()
        }
      }
    ])

    const logout = async () => {
      await store.dispatch('logout')
      router.push('/login')
    }

    const toggle = (event: any) => {
      menu.value.toggle(event)
    }

    return { logout, toggle, menu, items }
  },
})
