
import api from '@/services/api'
import { defineComponent, ref } from 'vue'
import { GroupRequest } from '@/types/Groups'
import { useRouter } from 'vue-router'
import { useStore } from '@/store/index'
import { useToast } from 'primevue/usetoast'

export default defineComponent({
  components: {},
  setup() {
    const router = useRouter()
    const store = useStore()
    const toast = useToast()
    const newGroup = ref<GroupRequest>({color: 'ff0000'} as GroupRequest)
    const formValid = ref<boolean>(false)
    const fieldValidation = ref({
      name: '',
      prefix: ''
    })

    const handleSubmit = async () => {
      await validateForm()
      if (!formValid.value) {
        return false
      }

      api.post('manager/group', JSON.stringify(newGroup.value))
      .then(() => {
        store.dispatch('fetchGroups')
        router.push('/groups')
      }).catch(() => {
        toast.add({ severity: 'error', summary: 'Something went wrong. Check if that group/prefix already exists.', life: 3000})
      })
    }

    const validateForm = () => {
      fieldValidation.value.name = newGroup.value.name.length <= 0 ? 'Group must have name' : ''
      fieldValidation.value.prefix = newGroup.value.initials?.length != 3 ? 'Groups initials must be exactly 3 characters' : ''

      formValid.value = !fieldValidation.value.name.length && !fieldValidation.value.prefix.length
    }

    return { 
      newGroup,
      formValid,
      fieldValidation,
      handleSubmit, 
    }
  }
})
