
import api from '@/services/api'
import { format } from 'date-fns'
import { AuditElement } from '@/types/Audit';
import { defineComponent, onMounted, ref } from "@vue/runtime-core";
import { PaginBodyDefault } from '@/consts/PaginationConst';
import { PaginationBody } from '@/types/Misc';
import { toastError } from '@/helpers/error-handling';
import { useToast } from 'primevue/usetoast';

export default defineComponent({
  props: ['id'],
  setup(props) {

    const tableBody = ref({ ...PaginBodyDefault })
    const toast = useToast()

    const audit = ref<AuditElement[]>([])
    const auditCount = ref(0)
    const currentUser = ref<string>('')
    let searchTimeout = ref(0)

    onMounted(() => {
      fetchAudit()
      currentUser.value = props.id
    })

    const fetchAudit = () => {
      api.put(`manager/audit/user/${props.id}`, JSON.stringify(tableBody.value))
        .then(res => {
          audit.value = res?.data.data.list
          auditCount.value = res?.data.data.pageInfo.totalCount
        }).catch((err: any) => toastError(toast, err))
    }

    const returnDate = (ts: number): string => {
      return format(new Date(ts * 1000), 'dd-MM-yyyy, H:mm:ss')
    }

    const searchAudit = () => {
      if (searchTimeout.value) {
        clearTimeout(searchTimeout.value)
        searchTimeout.value = 0
      }
      searchTimeout.value = window.setTimeout(() => {
        const searchLength = tableBody.value.searchValue.length
        if (searchLength >= 3 || searchLength === 0) {
          tableBody.value.pageNumber = 1
          fetchAudit()
        }
      },800)
    }

    const paginChange = (payload: PaginationBody) => {
      tableBody.value = {...payload}
      fetchAudit()
    }

    return {
      props,
      audit,
      currentUser,
      auditCount,
      tableBody,
      returnDate,
      paginChange,
      searchAudit
    }
  }
})

