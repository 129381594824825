
import api from '@/services/api'
import { defineComponent, onMounted, ref } from 'vue'
import { Group, GroupRequest } from '@/types/Groups'
import { useRouter } from 'vue-router'
import { toastError } from '@/helpers/error-handling'
import { useToast } from 'primevue/usetoast'

export default defineComponent({
  props: ['id'],
  components: {},
  setup(props) {
    const router = useRouter()
    const toast = useToast()
    
    const userStatus = ref<string>('active')

    const group = ref<Group>({} as Group)
    const groups = ref<Group[]>([])
    const isPrefixSet = ref<boolean>(true)

    onMounted(() => {
      fetchData()
    })

    const fetchData = async () => {
      try {
        const { data } = await api.get(`manager/group/${props.id}`)
        group.value = data.data
        isPrefixSet.value = group.value?.initials?.length > 0
      } catch (err) {
        toastError(toast, err)
      }
    }

    const handleSubmit = () => {
      const groupUpdate: GroupRequest = {
        externalId: group.value.externalId,
        name: group.value.name,
        color: group.value.color,
        initials: isPrefixSet.value ? null : group.value.initials
      };
      api.put('manager/group', JSON.stringify(groupUpdate))
        .then(() => router.push('/groups'))
        .catch((err: any) => toastError(toast, err))
    }

    return { 
      group,
      userStatus,
      groups,
      isPrefixSet,
      handleSubmit,
    }
  }
})
