
import api from '@/services/api'
import { computed, defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { PaginationBody } from '@/types/Misc'
import { useStore } from '@/store/index'
import { parseDateShortFormat } from '@/helpers/table-utils'
import { Group } from '@/types/Groups'
import { PaginBodyDefault } from '@/consts/PaginationConst'
import { Voucher, VoucherUpdate } from '@/types/Voucher'
import { toastError } from '@/helpers/error-handling'
import { useToast } from 'primevue/usetoast'

export default defineComponent({
  components: {},
  setup() {
    const store = useStore()
    const router = useRouter()
    const toast = useToast()

    const tableBody = ref({ ...PaginBodyDefault })
    let searchTimeout = ref<number | null>(0)

    store.dispatch('fetchVouchers', tableBody.value)

    const tableUtilsVisible = ref<boolean>(false)

    const voucherList = computed(() => store.state.voucherList)
    const vouchersCount = computed<number>(() => store.state.vouchersCount)
    const groups = computed<Group[]>(() => store.state.groupList)
    
    const minCount = computed<number>(() => {
      return tableBody.value.pageNumber * tableBody.value.pageSize - (tableBody.value.pageSize - 1)
    })
    const maxCount = computed<number>(() => {
      return tableBody.value.pageNumber * tableBody.value.pageSize
    })

    const searchVouchers = () => {
      if (searchTimeout.value) {
        clearTimeout(searchTimeout.value)
        searchTimeout.value = null
      }
      searchTimeout.value = window.setTimeout(() => {
        const searchLength = tableBody.value.searchValue.length
        if (searchLength >= 3 || searchLength === 0) {
          tableBody.value.pageNumber = 1
          store.dispatch('fetchVouchers', tableBody.value)
        }
      },800)
    }

    const onPaginationUpdate = () => {
      store.dispatch('fetchVouchers', tableBody.value)
    }

    const addVouchers = () => {
      router.push({name: 'AddVoucherView' })
    }

    const parseVouchersValue = (voucher: number | undefined) => {
      return voucher ? `${voucher} day(s)` : 'Unlimited'
    }

    const paginChange = (payload: PaginationBody) => {
      tableBody.value = {...payload}
      onPaginationUpdate()
    }

    const findGroup = (groupId: string): Group | undefined => {
      return groups.value.find(item => item.externalId === groupId)
    }

    const updateVoucher = (voucher: Voucher) => {
      const newVoucher: VoucherUpdate = {
        externalId: voucher.externalId,
        lifeTime: voucher.lifeTime,
        isTester: voucher.isTester,
        isReserved: voucher.isReserved
      }

      api.put('manager/voucher', JSON.stringify(newVoucher))
        .then(() => toast.add({ severity: 'info', summary: 'Voucher reservation status updated', life: 3000 }))
        .catch((err: any) => toastError(toast, err))
    }

    return {
      tableUtilsVisible,
      voucherList,
      minCount,
      maxCount,
      tableBody,
      vouchersCount,
      parseDateShortFormat,
      searchVouchers,
      addVouchers,
      parseVouchersValue,
      findGroup,
      paginChange,
      updateVoucher
    }
  },
})
