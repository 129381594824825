import TokenService from './token.service'
import api from '@/services/api'

class AuthService {
  login(userData: any) {
    return api.post('identity/login', userData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then(res => {
      if (res.data.access_token) {
        TokenService.setUserTokenData(res.data)
      }
      return res.data
    })
  }
  logout() {
    TokenService.removeUserTokenData()
  }
}
export default new AuthService();