const UserType = {
  StandardUser: 'User',
  ControlClinic: 'Control',
  ExperimentalClinic: 'Experimental'
} as const

enum BatchUserType {
  ControlClinic = 'Control',
  ExperimentalClinic = 'Experimental'
}

enum UploadedUserType {
  Standard = 0,
  Control = 1,
  Experimental = 2
}

export { UserType, UploadedUserType, BatchUserType }