
import api from '@/services/api'
import { computed, defineComponent, ref } from 'vue'
import { Group } from '@/types/Groups'
import { useRouter } from 'vue-router'
import { useStore } from '@/store/index'
import { VoucherRequest } from '@/types/Voucher'
import { DropdownItem } from '@/types/Misc'
import { toastError } from '@/helpers/error-handling'
import { useToast } from 'primevue/usetoast'

export default defineComponent({
  components: {},
  setup() {
    const router = useRouter()
    const store = useStore()
    const toast = useToast()
    const newVoucher = ref<VoucherRequest>({} as VoucherRequest)
    const groupList = computed<Group[]>(() => store.state.groupList)
    const selectedGroup = ref<Group>({} as Group)
    const lifeTimeOptions = ref<DropdownItem[]>([
      {label: 'Unlimited', value: -1},
      {label: '7 days', value: 7},
      {label: '30 days', value: 30},
      {label: '90 days', value: 90},
      {label: '365 days', value: 365},
    ])
    const lifeTimeError = ref<boolean>(false)
    const groupError = ref<boolean>(false)

    const handleSubmit = async () => {
      if (selectedGroup.value.externalId) {
        newVoucher.value.groupExternalId = selectedGroup.value.externalId
        groupError.value = false
      } else {
        newVoucher.value.groupExternalId = ''
        groupError.value = true
      }
      lifeTimeError.value = !newVoucher.value.lifeTime
      if (!lifeTimeError.value) {
        api.post('manager/voucher', newVoucher.value)
          .then(() => router.push('/vouchers'))
          .catch((err: any) => toastError(toast, err))
      }
    }

    return { 
      newVoucher,
      groupList,
      selectedGroup,
      lifeTimeOptions,
      lifeTimeError,
      groupError,
      handleSubmit
    }
  }
})
