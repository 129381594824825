
import { defineComponent, computed, PropType } from 'vue'
import { PaginationBody } from '@/types/Misc'

export default defineComponent({
  props: {
    maxCount: {
      type: Number,
      default: 0
    },
    options: {
      type: Object as PropType<PaginationBody>,
      required: true
    }
  },
  components: {},
  emits: ['paginChange'],
  setup(props, { emit }) {
    
    const paginDropdownOptions = [
      {name: 50, value: 50},
      {name: 100, value: 100},
      {name: 150, value: 150},
    ]

    const paginOptions = computed(() => ({ ...props.options }))

    const minPageCount = computed(() => {
      return paginOptions.value.pageNumber * paginOptions.value.pageSize - (paginOptions.value.pageSize - 1)
    })
    const maxPageCount = computed(() => {
      const count = paginOptions.value.pageNumber * paginOptions.value.pageSize
      return count <= listCount.value ? count : listCount.value
    })
    const listCount = computed(() => props.maxCount)

    const sendChange = () => {
      emit('paginChange', paginOptions.value)
    }

    const nextPage = () => {
      if (!(paginOptions.value.pageSize * paginOptions.value.pageNumber >= listCount.value)) {
        paginOptions.value.pageNumber += 1
        sendChange()
      }
    }

    const prevPage = () => {
      if(paginOptions.value.pageNumber > 1) {
        paginOptions.value.pageNumber -= 1
        sendChange()
      }
    }

    return { 
      paginDropdownOptions,
      paginOptions,
      minPageCount,
      maxPageCount,
      listCount,
      prevPage,
      nextPage,
      sendChange
    }
  },
})
