
import { AccessRole } from '@/enums/UserRole'
import { defineComponent, ref, onMounted } from 'vue'
import { useRouter, RouterLink } from 'vue-router'
import { useStore } from '@/store/index'

export default defineComponent({
  components: {
    RouterLink
  },
  setup() {
    const router = useRouter()
    const store = useStore()

    const username = ref('')
    const password = ref('')
    const formErr = ref('')
    const code = ref('')
    const codeConfirmVisible = ref(false)
    const counter = ref<number>(0)
    let checkInterval = 0

    onMounted(() => {
      store.dispatch('cleanUser')
    })

    const confirmCred = async () => {
      if (!validateLoginForm(username.value, password.value)) {
        return false
      }
      const data: URLSearchParams = new URLSearchParams({
        username: username.value,
        grant_type: 'password',
        password: password.value,
        scope: 'api1 offline_access'
      })
      try {
        await store.dispatch('login', {user: data, lastStep: false})
        codeConfirmVisible.value = true
      } catch (err: any) {
        formErr.value = err.response.data ? err.response.data : 'Wrong username and/or password.'
      }
    }

    const confirmCode = async () => {
      const data: URLSearchParams = new URLSearchParams({
        authValue: username.value,
        grant_type: 'auth_code',
        userCode: code.value,
        scope: 'api1 offline_access'
      })
      try {
        await store.dispatch('login', {user: data, lastStep: true})
        router.push('/users/all')
      }
      catch (err: any) {
        console.log('code error', err.message)
      }
    }

    const resendCode = () => {
      confirmCred()
      counter.value = 60
      checkInterval = setInterval(() => {
        if (counter.value) {
          counter.value--
        } else {
          clearInterval(checkInterval)
        }
      }, 1000)
    }

    const validateLoginForm = ( username: string, password: string ) => {
      if (username.length && password.length) {
        formErr.value = ''
        return true
      } else {
        formErr.value = 'Please fill out form fields.'
        return false
      }
    }

    return {
      username,
      password,
      formErr,
      code,
      codeConfirmVisible,
      counter,
      confirmCred,
      confirmCode,
      resendCode
    }
  },
})
