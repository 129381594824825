
import api from '@/services/api'
import { computed, defineComponent, ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useRouter } from 'vue-router'
import { useStore } from '@/store/index'
import { BatchUserType } from '@/enums/UserType'
import { Group } from '@/types/Groups'
import { DropdownItem } from '@/types/Misc'
import { GenerateUsersRequest } from '@/types/User'
import { toastError } from '@/helpers/error-handling'
import { AxiosResponse } from 'axios'

export default defineComponent({
  components: {},
  setup() {
    const router = useRouter()
    const toast = useToast()
    const store = useStore()
    const selectedGroup = ref<Group>({} as Group)
    const groupList = computed<Group[]>(() => store.state.groupList)
    
    const userTypeEnum = BatchUserType
    const userTypeOptions = Object.keys(userTypeEnum).map(key => ({
      label: userTypeEnum[key as keyof typeof userTypeEnum], value: key
    } as DropdownItem))
    let checkInterval: any = null
    const isUploading = ref(false)

    const newBatch = ref<GenerateUsersRequest>({} as GenerateUsersRequest)

    const handleSubmit = () => {
      newBatch.value.groupExternalId = selectedGroup.value.externalId
      let processId = ''
      api.post('manager/userjob/generate', JSON.stringify(newBatch.value))
        .then((res: AxiosResponse) => {
          processId = res?.data.data
          isUploading.value = true
          checkInterval = setInterval(() => checkProgress(processId), 1000)
        })
        .catch((err: any) => toastError(toast, err))
    }

    const checkProgress = (sessionId: string) => {
      api.get(`manager/usersync/${sessionId}`)
        .then((res: AxiosResponse) => {
          if (res?.data.data?.isFinished) {
            clearInterval(checkInterval)
            isUploading.value = false
            toast.add({ severity: 'info', summary: `${newBatch.value.numbers} succesfully added!`, life: 3000})
            router.push('/users/all')
          }
        })
        .catch((err: any) => toastError(toast, err))
    }

    return {
      newBatch,
      selectedGroup,
      groupList,
      userTypeOptions,
      handleSubmit
    }
  },
})
