enum UserRole {
  Administrator = 'Administrator',
  Moderator = 'Moderator'
}

const ManagementRole = {
  Administrator: 'Administrator',
  Manager: 'Manager'
} as const

const AccessRole = {
  Administrator: 'Administrator',
  Manager: 'Manager'
} as const

export { UserRole, ManagementRole, AccessRole }