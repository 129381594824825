import axios from 'axios'
import axiosInstance from './api'
import TokenService from './token.service'
import Config from "@/config.json"
import type { AppStore } from "@/store/index"

const setup = ({ dispatch }: AppStore) => {
  let requestCount = 0
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken()
      requestCount++
      if (requestCount == 1) dispatch('setLoading', true)
      if (token && config.headers) {
        config.headers["Authorization"] = 'Bearer ' + token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axiosInstance.interceptors.response.use(
    (res) => {
      requestCount--
      if (!requestCount) dispatch('setLoading', false)
      return res
    },
    async (err) => {
      const originalConfig = err.config
      if (originalConfig.url !== 'identity/login' && err.response) {
        // Access token expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            const authUri = Config.AUTH_URI === '$AUTH_URI' ? process.env.VUE_APP_AUTH_URI : Config.AUTH_URI
            const uri = `${authUri}identity/login`
            const body = new URLSearchParams({
              grant_type: 'refresh_token',
              refresh_token: TokenService.getLocalRefreshToken()
            })
            const rs = await axios.post(uri,
              body,
              {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                }
              }
            )
            await dispatch('refreshUser', rs.data)
            await TokenService.updateLocalAccessToken(rs.data.access_token)
            await TokenService.refreshUserTokenData(rs.data)
            return axiosInstance(originalConfig)
          } catch (_error) {
            await TokenService.removeUserTokenData()
            window.location.replace(`${window.location.origin}/`)
            return Promise.reject(_error)
          }
        } 
      }
      requestCount--
      if (!requestCount) dispatch('setLoading', false)
      return Promise.reject(err);
    }
  )
}

export default setup