
import api from '@/services/api'
import { defineComponent, ref } from 'vue'
import { ModeratorRequest } from '@/types/Moderator'
import { useRouter } from 'vue-router'
import { DropdownItemWithStatus } from '@/types/Misc'
import { ManagementRole } from '@/enums/UserRole'
import { toastError } from '@/helpers/error-handling'
import { useToast } from 'primevue/usetoast'

export default defineComponent({
  components: {},
  setup() {
    const router = useRouter()
    const toast = useToast()
    const managementRoleType = ManagementRole
    const managementRoleOptions = Object.keys(managementRoleType).map(key => ({
      label: managementRoleType[key as keyof typeof managementRoleType],
      value: key,
      disabled: managementRoleType[key as keyof typeof managementRoleType] == managementRoleType.Administrator
    } as DropdownItemWithStatus))
    
    const moderator = ref<ModeratorRequest>({} as ModeratorRequest)

    const handleSubmit = () => {
      const newModerator: ModeratorRequest = { 
        ...moderator.value,
      }
      api.post('admin/user', JSON.stringify(newModerator))
      .then(() => {
        router.push('/management')
      }).catch((err: any) => toastError(toast, err))
    }

    return { 
      moderator,
      managementRoleOptions,
      handleSubmit 
    }
  }
})
