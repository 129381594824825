
import { AccessRole } from '@/enums/UserRole'
import { Group } from '@/types/Groups'
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useStore } from '@/store/index'

export default defineComponent({
  components: {},
  setup() {
    const store = useStore()
    const accessIsSet = ref(false)

    const isVisibleFor = ((roles: string[]) => {
      return roles.includes(store.state.userType)
    })

    const groupsMenu = computed(() => {
      if (!isVisibleFor([AccessRole.Administrator, AccessRole.Manager])) {
        return null
      }

      return store.state.groupList.map((group: Group) => {
        return({
          name: group.name,
          to: `/users/${group.externalId}`,
          visible: true
        })
      }).sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'accent' }))
    })

    const showSubmenu = ref<boolean>(false)

    const menu = computed(() => [
      {
        name: 'Users',
        to: '/users/all',
        visible: isVisibleFor([AccessRole.Administrator, AccessRole.Manager]),
      },
      {
        name: 'Groups',
        to: '/groups',
        visible: isVisibleFor([AccessRole.Administrator, AccessRole.Manager]),
        menuOpen: showSubmenu.value,
        children: groupsMenu.value
      },
      {
        name: 'Vouchers',
        to: '/vouchers',
        visible: isVisibleFor([AccessRole.Administrator, AccessRole.Manager]),
      },
      {
        name: 'Management',
        to: '/management',
        visible: isVisibleFor([AccessRole.Administrator]),
      },
      {
        name: 'Deletion',
        to: '/deletion',
        visible: isVisibleFor([AccessRole.Administrator, AccessRole.Manager]),
      },
      {
        name: 'Tools',
        to: '/tools',
        visible: isVisibleFor([AccessRole.Administrator, AccessRole.Manager])
      },
    ])

    onMounted(() => {
      store.dispatch('fetchGroups')
      setUser()
    })

    const setUser = async () => {
      await store.dispatch('setUserRole')
      accessIsSet.value = true
    }
    
    const toggleMenu = (item: any) => {
      showSubmenu.value = !showSubmenu.value
    }

    return { menu, accessIsSet, toggleMenu }
  },
})
