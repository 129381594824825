
import api from '@/services/api'
import { computed, defineComponent, onMounted, ref } from 'vue'
import { Moderator, ModeratorRequest, ModeratorStatusBody } from '@/types/Moderator'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import { useStore } from '@/store/index'
import { ManagementRole, UserRole } from '@/enums/UserRole'
import { useConfirm } from 'primevue/useconfirm'
import { DropdownItemWithStatus } from '@/types/Misc'
import { toastError } from '@/helpers/error-handling'

export default defineComponent({
  props: ['id'],
  components: {},
  setup(props) {
    const router = useRouter()
    const toast = useToast()
    const confirm = useConfirm()
    const store = useStore()
    const managementRoleType = ManagementRole
    const managementRoleOptions = Object.keys(managementRoleType).map(key => ({
      label: managementRoleType[key as keyof typeof managementRoleType],
      value: key,
      disabled: managementRoleType[key as keyof typeof managementRoleType] === managementRoleType.Administrator
    } as DropdownItemWithStatus))

    const moderator = ref<Moderator>({ } as Moderator)
    const modRole = ref<string>('')
    const isAdmin = computed<boolean>(() => store.state.userType === UserRole.Administrator)

    const statusBtnLabel = computed(() => {
      return moderator.value.activated ? 'Deactivate' : 'Activate'
    })

    onMounted(() => {
      fetchMod()
    })

    const fetchMod = (): void => {
      api.get(`admin/user/${props.id}`).then(res => {
        moderator.value = res?.data.data
        modRole.value = moderator.value.roles[0]
      }).catch((err: any) => toastError(toast, err))
    }

    const handleSubmit = async () => {
      const newModerator: ModeratorRequest = {
        email: moderator.value.email,
        name: moderator.value.name,
        phoneNumber: moderator.value.phoneNumber,
        role: modRole.value
      } as ModeratorRequest

      api.put(`admin/user/${moderator.value.externalId}`, JSON.stringify(newModerator))
        .then(() => router.push('/management'))
        .catch((err: any) => toastError(toast, err))
    }

    const setModStatus = () => {
      const body: ModeratorStatusBody = {
        externalId: moderator.value.externalId,
        status: !moderator.value.activated
      }
      api.post('admin/user/set-activate', JSON.stringify(body))
        .then(() => {
          fetchMod()
          toast.add({ severity: 'info', summary: 'Moderator status changed!', life: 3000 })
        }).catch((err: any) => toastError(toast, err))
    }

    const resendInvitation = () => {
      api.post('admin/user/resendinvitation', JSON.stringify({ externalId: moderator.value.externalId }))
        .then(() => toast.add({ severity: 'info', summary: 'Invitation sent!', life: 3000 }))
        .catch((err: any) => toastError(toast, err))
    }

    const resetPassword = () => {
      confirm.require({
        message: 'We will send you an email with link to set a new password. Do you want to continue?',
        header: 'Warning',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          api.post(
            `manager/user/resetpassword`,
            JSON.stringify({ externalId: moderator.value.externalId }))
          .then(() => {
            toast.add({ severity: 'info', summary: 'Email has been sent.', life: 3000 })
          })
          .catch((err: any) => toastError(toast, err))
        }
      })
    }

    const goToLog = () => {
      router.push(`/management-details/${props.id}/audit`)
    }

    return {
      moderator,
      statusBtnLabel,
      isAdmin,
      modRole,
      managementRoleOptions,
      handleSubmit,
      setModStatus,
      resendInvitation,
      resetPassword,
      goToLog
    }
  }
})
