<template>
  <Toast />
  <ConfirmDialog></ConfirmDialog>
  <div class="layout" v-if="isApiReady">
    <div class="layout__sidebar">
      <SidebarComponent v-if="isLoggedIn"/>
    </div>
    <div class="layout__main">
      <div class="layout__header">
        <HeaderComponent />
      </div>
      <div class="layout__view">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { useStore } from '@/store/index'
import HeaderComponent from './components/HeaderComponent'
import SidebarComponent from './components/SidebarComponent'

export default defineComponent({
  components: {
    HeaderComponent,
    SidebarComponent
  },
  setup() {
    const store = useStore()
    const isLoggedIn = computed(() => {
      return store.state.status.loggedIn
    })
    let isApiReady = ref(false);
    setTimeout(() => {
      isApiReady.value = true
    }, 500);

    return { isLoggedIn, isApiReady }
  },
})
</script>
